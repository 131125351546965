import { useEffect, useState } from 'react'

interface UseObserveElementOnTop {
    elementId: string
    observerOption?: IntersectionObserverInit
}

export const useObserveElementOnTop = ({ elementId, observerOption }: UseObserveElementOnTop) => {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if (elementId) {
            const element = document.getElementById(elementId)
            if (element) {
                const onLoad = () => {
                    if (element.offsetTop < window.scrollY) {
                        setIsVisible(true)
                    }
                    window.removeEventListener('load', onLoad)
                }
                window.addEventListener('load', onLoad)
                const defaultOptions = { root: null, rootMargin: '0px', threshold: 1.0 }
                const observer = new IntersectionObserver(entries => {
                    const [entry] = entries
                    setIsVisible(!entry.isIntersecting && element.offsetTop < window.scrollY)
                }, observerOption ?? defaultOptions)
                observer.observe(element)
                return () => {
                    observer.unobserve(element)
                    window.removeEventListener('load', onLoad)
                }
            }
        }
    }, [elementId, observerOption])

    return isVisible
}
